import React from 'react';
import { Nav } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import './style.css'; // Make sure to create and import a CSS file for custom styles
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faCog, faEnvelope, faMoneyBillTransfer, faSignOutAlt, faTableColumns, faTags, faUser } from '@fortawesome/free-solid-svg-icons';
import api from '../../../services/api';

const Sidebar = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const handleLogout = async () => {
        try {
            await api.post('logout');
            localStorage.removeItem('token');
            navigate('/');
        } catch (error) {
            console.error('Erro ao fazer logout:', error);
        }
    };

    return (
        <div className="d-flex flex-column vh-100 sidebar" style={{ width: '250px' }}>
            <div className="d-flex align-items-center justify-content-start m-4">
                <img src={process.env.PUBLIC_URL + "/assets/images/logo.webp"} alt="logo swiftsoft" className="logo" /> {/* Use the appropriate path to your logo */}
            </div>
            <Nav defaultActiveKey="/home" className="flex-column flex-grow-1">
                <Nav.Link
                    onClick={() => navigate('/home')}
                    className={`nav-link ${location.pathname === '/home' ? 'active' : ''}`}>
                    <FontAwesomeIcon icon={faTableColumns} className="me-2" /> Home
                </Nav.Link>
                <Nav.Link
                    onClick={() => navigate('/finance')}
                    className={`nav-link ${location.pathname === '/finance' ? 'active' : ''}`}>
                    <FontAwesomeIcon icon={faMoneyBillTransfer} className="me-2" /> Financeiro
                </Nav.Link>

                <Nav.Link
                    onClick={() => navigate('/products')}
                    className={`nav-link ${location.pathname === '/products' ? 'active' : ''}`}>
                    <FontAwesomeIcon icon={faTags} className="me-2" /> Produtos
                </Nav.Link>
                <div className="mt-auto">
                    <Nav.Link
                        onClick={() => navigate('/settings')}
                        className={`nav-link ${location.pathname === '/settings' ? 'active' : ''}`}>
                        <FontAwesomeIcon icon={faCog} className="me-2" /> Configurações
                    </Nav.Link>
                    <Nav.Link onClick={handleLogout}>
                        <FontAwesomeIcon icon={faSignOutAlt} className="me-2" /> Sair
                    </Nav.Link>
                </div>
            </Nav>
        </div>
    );
};

export default Sidebar;