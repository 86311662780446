import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";

import Login from "./pages/Login";
import ResetPassword from "./pages/ResetPassword";
import PrivateRoute from "./components/specific/PrivateRoute";
import { AuthProvider, useAuth } from "./contexts/Auth";
import RecoverPass from "./pages/RecoverPassword";
import Settings from "./pages/Settings";
import Unauthorized from "./pages/Errors/401";
import NotFound from "./pages/Errors/404";
import './index.css'
import Home from "./pages/Home";
import Finance from "./pages/Finance";
import Products from "./pages/Products";
import Checkout from "./pages/checkout";

const AnimatedRoutes = () => {
  const location = useLocation();

  return (
    <Routes location={location} key={location.pathname}>
      <Route path="*" element={<NotFound />} />
      <Route path="/unauthorized" element={<Unauthorized />} />
      <Route path="/" element={<Login />} />
      <Route path="/checkout/:productSecure" element={<Checkout />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/recover-password/:token" element={<RecoverPass />} />

      <Route element={<PrivateRoute />}>
        <Route path="/home" element={<Home />} />
        <Route path="/products" element={<Products />} />
        <Route path="/products/new" element={<Products />} />
        <Route path="/finance" element={<Finance />} />
        <Route path="/settings" element={<Settings />} />
      </Route>
    </Routes>
  );
};

const App = () => {
  const { loading } = useAuth();

  if (loading) {
    return <div>Carregando...</div>;
  }

  return (
    <AuthProvider>
      <BrowserRouter>
        <AnimatedRoutes />
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;