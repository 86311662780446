import React, { useState, useEffect } from 'react';
import Main from '../../components/specific/Main';
import { Button, Container, Pagination as BootstrapPagination } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import ProductModal from '../ProductModal';
import api from '../../services/api';
import { toast } from 'react-toastify';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import ReactivateProductModal from '../../components/specific/ReactivateProductModal';
import EditProductModal from '../../components/specific/EditProductModal';
import DeactivateProductModal from '../../components/specific/DeactivateProductModal';
import ProductCard from '../../components/specific/ProductCard';
import './Products.css';
import useWindowSize from '../../hooks/UseWindowSize';

export interface Product {
  id: number;
  secure_id: string;
  name: string;
  description: string;
  image: string;
  price: number | string;
  installments: number;
  quantity: number;
  deactivated_at?: string | null;
}

const Products: React.FC = () => {
  const { width } = useWindowSize();

  const [products, setProducts] = useState<Product[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [meta, setMeta] = useState<any>(null);
  const navigate = useNavigate();
  const location = useLocation();

  const [productToDeactivate, setProductToDeactivate] = useState<number | null>(null);
  const [productToReactivate, setProductToReactivate] = useState<number | null>(null);
  const [productToEdit, setProductToEdit] = useState<Product | null>(null);

  const handleNewProduct = () => {
    navigate('/products/new');
  };

  const handleCloseModal = () => {
    navigate('/products');
    fetchProducts(currentPage);
  };

  const handleEditProduct = (product: Product) => {
    setProductToEdit(product);
  };

  const handleCloseEditModal = () => {
    setProductToEdit(null);
    fetchProducts(currentPage);
  };

  const showModal = location.pathname === '/products/new';

  useEffect(() => {
    fetchProducts(currentPage);
  }, [currentPage]);

  const fetchProducts = async (page: number) => {
    try {
      const response = await api.get('/products', {
        params: {
          page: page,
          limit: 10,
        },
      });
      setProducts(response.data.data);
      setMeta(response.data.meta);
    } catch (error) {
      console.error('Erro ao buscar produtos:', error);
      toast.error('Erro ao carregar os produtos.');
    }
  };

  const handleDeactivateProduct = (productId: number) => {
    setProductToDeactivate(productId);
  };

  const handleCloseDeactivateModal = () => {
    setProductToDeactivate(null);
  };

  const handleConfirmDeactivate = async () => {
    if (productToDeactivate !== null) {
      try {
        await api.post(`/products/${productToDeactivate}/deactivate`);
        toast.success('Produto desativado com sucesso!');
        setProductToDeactivate(null);
        fetchProducts(currentPage);
      } catch (error) {
        toast.error('Erro ao desativar o produto.');
      }
    }
  };

  const handleReactivateProduct = (productId: number) => {
    setProductToReactivate(productId);
  };

  const handleCloseReactivateModal = () => {
    setProductToReactivate(null);
  };

  const handleConfirmReactivate = async () => {
    if (productToReactivate !== null) {
      try {
        await api.post(`/products/${productToReactivate}/reactivate`);
        toast.success('Produto reativado com sucesso!');
        setProductToReactivate(null);
        fetchProducts(currentPage);
      } catch (error) {
        toast.error('Erro ao reativar o produto.');
      }
    }
  };

  const responsiveBreakpoints = {
    0: {
      slidesPerView: 1.2,
    },
    768: {
      slidesPerView: 2,
    },
    992: {
      slidesPerView: 3,
    },
    1400: {
      slidesPerView: 4,
    },
  };

  const CustomPagination = () => {
    const items = [];
    for (let number = 1; number <= (meta ? meta.last_page : 1); number++) {
      items.push(
        <BootstrapPagination.Item key={number} active={number === currentPage} onClick={() => setCurrentPage(number)}>
          {number}
        </BootstrapPagination.Item>,
      );
    }

    return (
      <div className="d-flex justify-content-center">
        <BootstrapPagination>
          <BootstrapPagination.Prev onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1} />
          {items}
          <BootstrapPagination.Next onClick={() => setCurrentPage(currentPage + 1)} disabled={meta && currentPage === meta.last_page} />
        </BootstrapPagination>
      </div>
    );
  };

  return (
    <Main>
      <Container>
        {width >= 992 && (
          <Button variant="primary" onClick={handleNewProduct} className="mb-4">
            <FontAwesomeIcon icon={faPlus} /> Novo
          </Button>
        )}

        {showModal && (
          <ProductModal show={showModal} handleClose={handleCloseModal} />
        )}


        <Swiper
          spaceBetween={10}
          breakpoints={responsiveBreakpoints}
          pagination={{ clickable: true }}
          className="h-100 d-flex"
          navigation
          style={{ maxWidth: '91vw' }}
        >
          {products.map((product) => (
            <SwiperSlide
              key={product.id}
              className="h-100 d-flex"
              style={{ height: '100%' }}
            >
              <ProductCard
                product={product}
                handleEditProduct={handleEditProduct}
                handleDeactivateProduct={handleDeactivateProduct}
                handleReactivateProduct={handleReactivateProduct}
              />
            </SwiperSlide>
          ))}
        </Swiper>

          <CustomPagination />

        {/* Modais */}
        {productToDeactivate !== null && (
          <DeactivateProductModal
            show={productToDeactivate !== null}
            handleClose={handleCloseDeactivateModal}
            handleConfirm={handleConfirmDeactivate}
          />
        )}

        {productToReactivate !== null && (
          <ReactivateProductModal
            show={productToReactivate !== null}
            handleClose={handleCloseReactivateModal}
            handleConfirm={handleConfirmReactivate}
          />
        )}

        {productToEdit && (
          <EditProductModal
            show={!!productToEdit}
            handleClose={handleCloseEditModal}
            product={productToEdit}
          />
        )}
      </Container>
    </Main>
  );
};

export default Products;
