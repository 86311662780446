import React, { useState, ChangeEvent, FormEvent, useRef } from 'react';
import { Modal, Form, Row, Col } from 'react-bootstrap';
import api from '../../services/api';
import SButton from '../../components/common/Button';
import { formatCurrency, parseCurrency } from '../../utils/currency';
import { toast } from 'react-toastify';

interface ProductModalProps {
  show: boolean;
  handleClose: () => void;
}

const ProductModal: React.FC<ProductModalProps> = ({ show, handleClose }) => {
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [image, setImage] = useState<File | null>(null);
  const [price, setPrice] = useState<string>('');
  const [installments, setInstallments] = useState<number | ''>('');
  const [quantity, setQuantity] = useState<number | ''>('');
  const [validated, setValidated] = useState<boolean>(false);

  const priceInputRef = useRef<HTMLInputElement>(null);
  const imageInputRef = useRef<HTMLInputElement>(null);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false || !image) {
      e.stopPropagation();
      setValidated(true);
      return;
    }

    const formData = new FormData();
    formData.append('name', name);
    formData.append('description', description);
    if (image) {
      formData.append('image', image);
    }

    const numericPrice = parseCurrency(price).toString();
    formData.append('price', numericPrice);
    formData.append('installments', installments.toString());
    formData.append('quantity', quantity.toString());

    try {
      await api.post('/products', formData);
      toast.success('Produto cadastrado com sucesso!');
      // Limpar campos do formulário
      setName('');
      setDescription('');
      setImage(null);
      setPrice('');
      setInstallments('');
      setQuantity('');
      setValidated(false);

      // Limpar o campo de imagem
      if (imageInputRef.current) {
        imageInputRef.current.value = '';
      }

      handleClose();
    } catch (error) {
      toast.error('Erro ao cadastrar o produto.');
    }
  };

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setImage(e.target.files[0]);
    } else {
      setImage(null);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Cadastrar Produto</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group controlId="productName" className="mb-3">
            <Form.Label>Nome</Form.Label>
            <Form.Control
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <Form.Control.Feedback type="invalid">
              Por favor, informe o nome do produto.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="productDescription" className="mb-3">
            <Form.Label>Descrição</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
            <Form.Control.Feedback type="invalid">
              Por favor, informe a descrição do produto.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="productImage" className="mb-3">
            <Form.Label>Imagem</Form.Label>
            <Form.Control
              type="file"
              ref={imageInputRef}
              onChange={handleImageChange}
              required
            />
            <Form.Control.Feedback type="invalid">
              Por favor, envie uma imagem do produto.
            </Form.Control.Feedback>
          </Form.Group>

          <Row>
            <Col md={4}>
              <Form.Group controlId="productPrice" className="mb-3">
                <Form.Label>Valor</Form.Label>
                <Form.Control
                  type="text"
                  ref={priceInputRef}
                  value={price}
                  onChange={(e) => {
                    const formattedValue = formatCurrency(e.target.value);
                    setPrice(formattedValue);
                  }}
                  required
                  placeholder="Digite o valor"
                />
                <Form.Control.Feedback type="invalid">
                  Por favor, informe um valor válido.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={4}>
              <Form.Group controlId="productInstallments" className="mb-3">
                <Form.Label>Parcelamento (máx)</Form.Label>
                <Form.Control
                  type="number"
                  value={installments}
                  onChange={(e) => setInstallments(Number(e.target.value))}
                  required
                  min={1}
                />
                <Form.Control.Feedback type="invalid">
                  Por favor, informe o número máximo de parcelas.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={4}>
              <Form.Group controlId="productQuantity" className="mb-3">
                <Form.Label>Quantidade Disponível</Form.Label>
                <Form.Control
                  type="number"
                  value={quantity}
                  onChange={(e) => setQuantity(Number(e.target.value))}
                  required
                  min={0}
                />
                <Form.Control.Feedback type="invalid">
                  Por favor, informe a quantidade disponível.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <SButton
            variant="primary"
            type="submit"
            text="Cadastrar Produto"
            className="mt-3"
          />
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ProductModal;
