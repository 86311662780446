import React from 'react';
import { Modal, Button } from 'react-bootstrap';

interface DeactivateProductModalProps {
  show: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
}

const DeactivateProductModal: React.FC<DeactivateProductModalProps> = ({ show, handleClose, handleConfirm }) => {
  return (
    <Modal show={show} onHide={handleClose} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Desativar Produto</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Tem certeza de que deseja desativar este produto? Ele será removido das listagens ativas e será excluído após 30 dias.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancelar
        </Button>
        <Button variant="danger" onClick={handleConfirm}>
          Desativar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeactivateProductModal;