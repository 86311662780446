import React from 'react';
import SButton from '../Button';
import Input from '../Input';

const Swift = {
    Button: SButton,
    Input: Input
};

export default Swift;
