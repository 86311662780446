export function formatCurrency(value: string): string {
    // Remove todos os caracteres que não são dígitos
    const numericValue = value.replace(/\D/g, '');
  
    // Converte para número
    const numberValue = parseInt(numericValue, 10);
  
    // Se não for um número válido, retorna uma string vazia
    if (isNaN(numberValue)) {
      return '';
    }
  
    // Formata o número para moeda
    const formattedValue = (numberValue / 100).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  
    return formattedValue;
  }
  
  export function parseCurrency(value: string): number {
    // Remove todos os caracteres que não são dígitos ou vírgula
    const numericValue = value.replace(/[^0-9,]/g, '').replace(',', '.');
  
    // Converte para número
    const numberValue = parseFloat(numericValue);
  
    // Se não for um número válido, retorna zero
    if (isNaN(numberValue)) {
      return 0;
    }
  
    return numberValue;
  }
  