export const formatPhone = (value: string): string => {
  const phone = value.replace(/[^\d]/g, ""); // Remove todos os caracteres que não são dígitos

  if (phone.length === 0) {
      return ""; // Retorna vazio se não houver caracteres
  }

  if (phone.length <= 2) {
      return `(${phone}`; // Adiciona o primeiro parêntese conforme os primeiros dois dígitos
  }

  if (phone.length <= 6) {
      return `(${phone.slice(0, 2)}) ${phone.slice(2)}`; // Formata até o quarto dígito, incluindo o fechamento do parêntese
  }

  if (phone.length <= 10) {
      return `(${phone.slice(0, 2)}) ${phone.slice(2, 6)}-${phone.slice(6)}`; // Formata até o oitavo dígito
  }

  // Para 11 dígitos
  return `(${phone.slice(0, 2)}) ${phone.slice(2, 7)}-${phone.slice(7)}`;
};