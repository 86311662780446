import React, { useState, useEffect } from 'react';
import { Form, Button, Col, Row, Card, Modal } from 'react-bootstrap';
import './Checkout.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightToBracket, faCreditCard, faWifi3 } from '@fortawesome/free-solid-svg-icons';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import api from '../../services/api';
import { useParams } from 'react-router-dom';
import { formatPhone } from '../../utils/phone'; // Importa a função de máscara
import { toast } from 'react-toastify'; // Importa o toast do react-toastify
import Swift from '../../components/common/Swift';
import ThankYouModal from '../../components/specific/ThankYouModal';

// Use a chave pública de teste
const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY_TEST;

if (!stripePublicKey) {
  console.error('⚠️ Stripe public key not defined');
}

const stripePromise = loadStripe(stripePublicKey!);

const Checkout: React.FC = () => {

  const [loader, setLoader] = useState(false);

  const { productSecure } = useParams();
  const stripe = useStripe();
  const elements = useElements();

  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [installments, setInstallments] = useState('1');
  const [cardholderName, setCardholderName] = useState('');
  const [billingZip, setBillingZip] = useState('');
  const [calculatedPrice, setCalculatedPrice] = useState(0);
  const [validated, setValidated] = useState(false);
  const [cardError, setCardError] = useState('');

  // Estados para capturar os detalhes do cartão em tempo real
  const [cardNumber, setCardNumber] = useState('**** **** **** ****');
  const [cardExpiry, setCardExpiry] = useState('**/**');
  const [cardBrand, setCardBrand] = useState('');
  const [paymentStatus, setPaymentStatus] = useState<'success' | 'error' | ''>(''); // Estado para o status do pagamento
  const [showThankYouModal, setShowThankYouModal] = useState(false);
  const [isErrorModalPersistent, setIsErrorModalPersistent] = useState(false); // Estado para controlar se a modal de erro é persistente

  const [product, setProduct] = useState({
    id: '',
    name: '',
    description: '',
    image: '',
    price: 0,
    installments: 1,
    quantity: 0,
    deactivated_at: null,
  });

  const [showErrorModal, setShowErrorModal] = useState(false);

  // Função para buscar os dados do produto
  const fetchProductData = async () => {
    try {
      const response = await api.get(`/product/${productSecure}`);
      if (!response.data || Object.keys(response.data).length === 0 ) {
        setShowErrorModal(true);
        return;
      }
      if(response.data.deactivated_at != null){
        setShowErrorModal(true);
        setIsErrorModalPersistent(true);
      }
      setProduct(response.data);
      setCalculatedPrice(response.data.price);
    } catch (error) {
      console.error('Erro ao buscar os dados do produto:', error);
      setShowErrorModal(true);
    }
  };

  useEffect(() => {
    fetchProductData();
  }, [productSecure]);

  // Função para calcular o valor da parcela
  const calculateInstallmentPrice = (basePrice: number, installments: number) => {
    if (installments === 1) {
      return basePrice;
    }
    const installmentValue = (basePrice / installments) * 1.06 + 0.39;
    return (installmentValue * installments) / installments;
  };

  useEffect(() => {
    setCalculatedPrice(calculateInstallmentPrice(Number(product.price), parseInt(installments)));
  }, [installments, product.price]);

  // Função para processar o pagamento
  const handlePayment = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoader(true);
    setPaymentStatus(''); // Reset status

    const form = event.currentTarget as HTMLFormElement;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      setLoader(false);
      return;
    }

    if (!stripe || !elements) {
      console.error('⚠️ Stripe não foi carregado corretamente.');
      setLoader(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      console.error('⚠️ Elemento do cartão não encontrado.');
      setLoader(false);
      return;
    }

    const { error, token } = await stripe.createToken(cardElement, {
      name: cardholderName,
    });

    if (error) {
      console.error('⚠️ Erro ao criar PaymentMethod:', error);
      setCardError(error.message || 'Erro no cartão');
      setLoader(false);
      setPaymentStatus('error'); // Define status como erro
      return;
    }

    try {
      const response = await api.post('/create-payment', {
        name: cardholderName,
        email,
        phone,
        billingZip,
        token: token.id,
        productSecure,
        installments: parseInt(installments, 10),
      });

      const { success, error: backendError } = response.data;

      // Modifique o bloco de sucesso dentro da função handlePayment
      if (success) {
        setLoader(false);
        setPaymentStatus('success');
        setTimeout(() => {
          setShowThankYouModal(true); // Exibe o modal de agradecimento
        }, 1000);
        toast.success('Pagamento realizado com sucesso!');
      } else {
        setLoader(false);
        setPaymentStatus('error'); // Define status como erro
        toast.error(`Erro no pagamento: ${backendError}`);
      }
    } catch (backendError) {
      console.error('⚠️ Erro ao processar o pagamento:', backendError);
      toast.error('Erro ao processar o pagamento.');
      setPaymentStatus('error'); // Define status como erro
      setLoader(false);
    }
  };

  // Função para atualizar as informações do cartão em tempo real
  const handleCardChange = (event: any) => {
    if (event.error) {
      setCardError(event.error.message);
    } else {
      setCardError('');
    }
    setCardBrand(event.brand || '');
    if (event.elementType === 'cardNumber') {
      setCardNumber(event.empty ? '**** **** **** ****' : '**** **** **** ****');
    }
    if (event.elementType === 'cardExpiry') {
      setCardExpiry(event.empty ? '**/**' : '**/**');
    }
  };

  return (
    <div className="checkout-container d-flex justify-content-center align-items-center">
      <Card className="p-4 checkout-card">
        <Row>
          <Col md={9} className="left-section">
            <h3 className="mb-4 d-flex align-items-center">
              <div className="bg-coin">
                <img src={process.env.PUBLIC_URL + '/assets/images/logosf.webp'} alt="logo" />
              </div>
              &nbsp;
              <span style={{ color: '#111' }}>{'Swift Soft®'}</span>
            </h3>
            <Form noValidate validated={validated} onSubmit={handlePayment}>
              <Form.Group className="mb-3" controlId="cardholderName">
                <Form.Label>Nome do Titular</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nome como está no cartão"
                  className="input-field"
                  value={cardholderName}
                  onChange={(e) => setCardholderName(e.target.value.toUpperCase())} // Converte para maiúsculas
                  required
                  isInvalid={validated && !cardholderName}
                />
                <Form.Control.Feedback type="invalid">
                  Por favor, insira o nome do titular do cartão.
                </Form.Control.Feedback>
              </Form.Group>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Digite seu email"
                      className="input-field"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      isInvalid={validated && !email}
                    />
                    <Form.Control.Feedback type="invalid">
                      Por favor, insira um email válido.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="phone">
                    <Form.Label>Telefone</Form.Label>
                    <Form.Control
                      type="tel"
                      placeholder="(XX) XXXXX-XXXX"
                      className="input-field"
                      value={phone}
                      onChange={(e) => setPhone(formatPhone(e.target.value))} // Aplica a máscara ao telefone
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Por favor, insira um telefone válido.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Form.Group className="mb-3" controlId="cardDetails">
                <Form.Label>Detalhes do Cartão</Form.Label>
                <div className="input-field">
                  <CardElement
                    options={{
                      style: {
                        base: {
                          fontSize: '16px',
                          color: '#000',
                          '::placeholder': {
                            color: '#6c757d',
                          },
                          backgroundColor: '#ffffff',
                          padding: '10px',
                        },
                        invalid: {
                          color: '#fa755a',
                        },
                      },
                      hidePostalCode: true,
                    }}
                    onChange={handleCardChange}
                  />
                </div>
                {cardError && <div className="invalid-feedback d-block">{cardError}</div>}
              </Form.Group>

              <Form.Group className="mb-3" controlId="installments">
                <Form.Label>Parcelas</Form.Label>
                <Form.Control
                  as="select"
                  className="input-field"
                  value={installments}
                  onChange={(e) => setInstallments(e.target.value)}
                  required
                >
                  {[...Array(product.installments)].map((_, index) => (
                    <option key={index} value={index + 1}>
                      {index + 1}x
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Swift.Button
                className='btn bg-primary mt-4'
                loader={loader}
                text="Pagar"
                type="submit"
                icon={faArrowRightToBracket}
                onClick={() => setValidated(true)}
              />
            </Form>
          </Col>
          <Col md={3} className="right-section d-block">
            <Row className="justify-content-center" style={{ position: 'relative' }}>
              <Col xs={10}>
                <Card className={`pay-card ${paymentStatus === 'success' ? 'pay-card-success' : ''} ${paymentStatus === 'error' ? 'pay-card-error' : ''}`}>
                  <Card.Body style={{ padding: 0 }}>
                    <Row>
                      <Col xs={12} className="d-flex justify-content-between">
                        <div className="bg-coin2">
                          <FontAwesomeIcon icon={faCreditCard} style={{ fontSize: '12px' }} />
                        </div>
                        <FontAwesomeIcon icon={faWifi3} style={{ color: '#aaaaaa' }} />
                      </Col>
                      <Col xs={12}>
                        <Card.Text className="label-ticket fw-bold">&nbsp;</Card.Text>
                        <Card.Text className="label-ticket fw-bold mb-0">&nbsp;</Card.Text>
                        <Card.Text className="label-ticket name-card">{cardholderName || 'NOME DO TITULAR'}</Card.Text>
                        <Card.Text className="label-ticket name-card">{cardNumber}</Card.Text>
                        <div className="d-flex align-items-center justify-content-between">
                          <Card.Text className="label-ticket name-card mb-0">{cardExpiry}</Card.Text>
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/brand/${cardBrand !== 'unknown' ? cardBrand : 'visa'}.svg`}
                            alt={cardBrand}
                            style={{ height: '12px' }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Card className="fake-card">
              <Card.Body>
                <Card.Text></Card.Text>
              </Card.Body>
            </Card>
            <Card className="ticket">
              <Card.Body>
                <Card.Text className="fake-card"></Card.Text>
                <Card.Text className="label-ticket fw-bold">{product.name}</Card.Text>
                <Card.Text className="label-ticket">
                  {product.description ? product.description.substring(0, 50) : ''}
                  {product.description && product.description.length > 50 ? '...' : ''}
                </Card.Text>
                <Card.Text className="label-ticket">Parcelas: {installments}x</Card.Text>
              </Card.Body>
            </Card>
            <div className="separation-line"></div>
            <Card className="ticket-bottom">
              <span className="label-ticket">Investimento de:</span>
              <div>
                <strong>
                  R$ {Math.floor(calculatedPrice).toLocaleString()}
                </strong>
                <small>{(calculatedPrice % 1).toFixed(2).slice(1).replace('.', ',')}</small>
              </div>
            </Card>
          </Col>
        </Row>
      </Card>

     {/* Modal de Erro */}
     <Modal
        show={showErrorModal}
        onHide={!isErrorModalPersistent ? () => setShowErrorModal(false) : undefined}
        centered
        backdrop={isErrorModalPersistent ? 'static' : true}
        keyboard={!isErrorModalPersistent}
      >
        <Modal.Header closeButton={!isErrorModalPersistent}>
          <Modal.Title>Erro</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isErrorModalPersistent
            ? 'Este produto está desativado e não pode ser comprado no momento.'
            : 'Não foi possível encontrar o produto. Verifique as informações e tente novamente.'}
        </Modal.Body>
        <Modal.Footer>
          {!isErrorModalPersistent && (
            <Button variant="secondary" onClick={() => setShowErrorModal(false)}>
              Fechar
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      <ThankYouModal
        show={showThankYouModal}
        onClose={() => window.open(`https://swiftsoft.com.br`)}
      />;
    </div>
  );
};

const CheckoutWrapper: React.FC = () => {
  return (
    <Elements stripe={stripePromise}>
      <Checkout />
    </Elements>
  );
};

export default CheckoutWrapper;
