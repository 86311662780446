import React from 'react';
import { Modal, Button } from 'react-bootstrap';

interface ReactivateProductModalProps {
  show: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
}

const ReactivateProductModal: React.FC<ReactivateProductModalProps> = ({
  show,
  handleClose,
  handleConfirm,
}) => {
  return (
    <Modal show={show} onHide={handleClose} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Reativar Produto</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Tem certeza de que deseja reativar este produto? Ele voltará a ser exibido nas listagens ativas.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancelar
        </Button>
        <Button variant="success" onClick={handleConfirm}>
          Reativar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ReactivateProductModal;
