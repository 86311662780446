import React from 'react';
import { Navbar} from 'react-bootstrap';
import './style.css'; // Certifique-se de criar e importar um arquivo CSS para estilos personalizados
import DropdownSearch from '../../common/DropdownSearch';

const CustomNavbar: React.FC = () => {
    return (
        <Navbar expand="lg" className="custom-navbar">
            <DropdownSearch /> 
        </Navbar>
    );
};

export default CustomNavbar;
