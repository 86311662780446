import React, { useState } from 'react';
import { Card, Button, OverlayTrigger, Tooltip, Row, Col } from 'react-bootstrap';
import { Product } from '../../../pages/Products';

interface ProductCardProps {
  product: Product;
  handleEditProduct: (product: Product) => void;
  handleDeactivateProduct: (productId: number) => void;
  handleReactivateProduct: (productId: number) => void;
}

const ProductCard: React.FC<ProductCardProps> = ({
  product,
  handleEditProduct,
  handleDeactivateProduct,
  handleReactivateProduct,
}) => {
  const isDeactivated = product.deactivated_at ? product.deactivated_at : null;
  const [copyButtonText, setCopyButtonText] = useState('Link');

  const handleCopyLink = () => {
    const checkoutLink = `${window.location.origin}/checkout/${product.secure_id}`;
    navigator.clipboard.writeText(checkoutLink).then(() => {
      setCopyButtonText('Copiado!');
      setTimeout(() => setCopyButtonText('Link'), 2000); // Retorna para "Link" após 2 segundos
    });
  };

  return (
    <Card
      className={`h-100 ${isDeactivated ? 'bg-light' : ''}`}
      style={{ overflow: 'hidden', margin: '10px', minHeight: '450px' }}
    >
      <div style={{ position: 'relative' }}>
        <Card.Img
          variant="top"
          src={`${process.env.REACT_APP_API_URL}${product.image}`}
          style={{ height: '200px', objectFit: 'cover' }}
        />
        {isDeactivated && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              color: 'white',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '1.5rem',
              fontWeight: 'bold',
            }}
          >
            Desativado
          </div>
        )}
      </div>
      <Card.Body className="d-flex flex-column">
        <Card.Title>{product.name}</Card.Title>
        <Card.Text>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id={`tooltip-description-${product.id}`}>
                {product.description}
              </Tooltip>
            }
          >
            <span style={{ cursor: 'pointer' }}>
              {product.description.length > 100
                ? product.description.substring(0, 55) + '...'
                : product.description}
            </span>
          </OverlayTrigger>
        </Card.Text>
        <Row>
          <Col xs={8}>
            <Card.Text>
              <strong>
                {new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }).format(Number(product.price))}
              </strong>{' '}
              até {product.installments}x
            </Card.Text>
          </Col>
          <Col xs={4}>
            <Card.Text>
              {product.quantity} {product.quantity > 1 ? 'itens' : 'item'}
            </Card.Text>
          </Col>
        </Row>
        <div className="mt-auto d-flex justify-content-between">
          {isDeactivated && (
            <Card.Text className="text-danger mt-2">
              Desativado em{' '}
              {new Date(product.deactivated_at!).toLocaleDateString()}
            </Card.Text>
          )}
        </div>
        <div className="mt-auto d-flex justify-content-between">
          {isDeactivated ? (
            <Button
              variant="success"
              onClick={() => handleReactivateProduct(product.id)}
            >
              Reativar
            </Button>
          ) : (
            <>
              <Button
                variant="danger"
                onClick={() => handleDeactivateProduct(product.id)}
              >
                Desativar
              </Button>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id={`tooltip-copy-${product.id}`}>Copiar link</Tooltip>}
              >
                <Button
                  variant="secondary"
                  onClick={handleCopyLink}
                >
                  {copyButtonText}
                </Button>
              </OverlayTrigger>
              <Button variant="primary" onClick={() => handleEditProduct(product)}>
                Editar
              </Button>
            </>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default ProductCard;
