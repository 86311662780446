import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import Confetti from 'react-confetti';
import useWindowSize from '../../../hooks/UseWindowSize';

interface ThankYouModalProps {
  show: boolean;
  onClose: () => void;
}

const ThankYouModal: React.FC<ThankYouModalProps> = ({ show, onClose }) => {
  const { width, height } = useWindowSize(); // Usa o hook para pegar as dimensões da janela
  const [isConfettiActive, setIsConfettiActive] = useState(false);

  useEffect(() => {
    if (show) {
      setIsConfettiActive(true);
    }
  }, [show]);

  return (
    <>
      {isConfettiActive && (
        <Confetti
          className="confetti-pay"
          width={width}
          height={height}
          recycle={false} // Confetti não se repete
        />
      )}
      <Modal show={show} onHide={onClose} centered backdrop="static" keyboard={false}>
        <Modal.Header className="border-0 justify-content-center">
          <FontAwesomeIcon icon={faCheckCircle} style={{ color: '#4CAF50', fontSize: '7rem' }} />
        </Modal.Header>
        <Modal.Body>
          <h4 className="text-center">Parabéns pela sua escolha!</h4>
          <p className="text-justify">
            <center>Estamos muito felizes em ter você com a gente. </center><br/>Em breve, você receberá todas as informações da sua compra no
            seu e-mail. Obrigado(a) por confiar em nossos serviços e conte conosco para o que precisar!
          </p>

          <Button variant="success" onClick={onClose} className="mt-3" style={{ width: '100%' }}>
            OK
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ThankYouModal;
