import React, { useState, ChangeEvent, FormEvent, useRef, useEffect } from 'react';
import { Modal, Form, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { formatCurrency, parseCurrency } from '../../../utils/currency';
import api from '../../../services/api';
import SButton from '../../common/Button';

interface EditProductModalProps {
  show: boolean;
  handleClose: () => void;
  product: Product;
}

interface Product {
  id: number;
  name: string;
  description: string;
  image: string;
  price: number | string;
  installments: number;
  quantity: number;
  deactivated_at?: string | null;
}

const EditProductModal: React.FC<EditProductModalProps> = ({
  show,
  handleClose,
  product,
}) => {
  const [name, setName] = useState<string>(product.name);
  const [description, setDescription] = useState<string>(product.description);
  const [image, setImage] = useState<File | null>(null);
  const [price, setPrice] = useState<string>(
    formatCurrency(product.price.toString())
  );
  const [installments, setInstallments] = useState<number | ''>(
    product.installments
  );
  const [quantity, setQuantity] = useState<number | ''>(product.quantity);
  const [validated, setValidated] = useState<boolean>(false);

  const priceInputRef = useRef<HTMLInputElement>(null);
  const imageInputRef = useRef<HTMLInputElement>(null);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
      return;
    }

    const formData = new FormData();
    formData.append('name', name);
    formData.append('description', description);
    if (image) {
      formData.append('image', image);
    }
    const numericPrice = parseCurrency(price).toString();
    formData.append('price', numericPrice);
    formData.append('installments', installments.toString());
    formData.append('quantity', quantity.toString());

    try {
      await api.post(`/products/${product.id}/update`, formData);
      toast.success('Produto atualizado com sucesso!');
      handleClose();
    } catch (error) {
      toast.error('Erro ao atualizar o produto.');
    }
  };

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setImage(e.target.files[0]);
    } else {
      setImage(null);
    }
  };

  useEffect(() => {
    setName(product.name);
    setDescription(product.description);
    setPrice(formatCurrency(product.price.toString()));
    setInstallments(product.installments);
    setQuantity(product.quantity);
  }, [product]);

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Editar Produto</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group controlId="productName" className="mb-3">
            <Form.Label>Nome</Form.Label>
            <Form.Control
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <Form.Control.Feedback type="invalid">
              Por favor, informe o nome do produto.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="productDescription" className="mb-3">
            <Form.Label>Descrição</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
            <Form.Control.Feedback type="invalid">
              Por favor, informe a descrição do produto.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="productImage" className="mb-3">
            <Form.Label>Imagem</Form.Label>
            <Form.Control
              type="file"
              ref={imageInputRef}
              onChange={handleImageChange}
            />
            <Form.Text className="text-muted">
              Deixe em branco para manter a imagem atual.
            </Form.Text>
          </Form.Group>

          <Row>
            <Col md={4}>
              <Form.Group controlId="productPrice" className="mb-3">
                <Form.Label>Valor</Form.Label>
                <Form.Control
                  type="text"
                  ref={priceInputRef}
                  value={price}
                  onChange={(e) => {
                    const formattedValue = formatCurrency(e.target.value);
                    setPrice(formattedValue);
                  }}
                  required
                  placeholder="Digite o valor"
                />
                <Form.Control.Feedback type="invalid">
                  Por favor, informe um valor válido.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={4}>
              <Form.Group controlId="productInstallments" className="mb-3">
                <Form.Label>Parcelamento (máx)</Form.Label>
                <Form.Control
                  type="number"
                  value={installments}
                  onChange={(e) => setInstallments(Number(e.target.value))}
                  required
                  min={1}
                />
                <Form.Control.Feedback type="invalid">
                  Por favor, informe o número máximo de parcelas.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={4}>
              <Form.Group controlId="productQuantity" className="mb-3">
                <Form.Label>Quantidade Disponível</Form.Label>
                <Form.Control
                  type="number"
                  value={quantity}
                  onChange={(e) => setQuantity(Number(e.target.value))}
                  required
                  min={0}
                />
                <Form.Control.Feedback type="invalid">
                  Por favor, informe a quantidade disponível.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <SButton
            variant="primary"
            type="submit"
            text="Salvar Alterações"
            className="mt-3"
          />
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditProductModal;
