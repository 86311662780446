import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserFriends, faPlus, faHouse, faMoneyBillTransfer, faTags } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useLocation } from 'react-router-dom';
import './style.css';


const BottomNavbar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handlePlusClick = () => {
    if (location.pathname === '/products') {
      navigate('/products/new');
    }
    // Adicionar outras condições para diferentes páginas
  };

  return (
    <Navbar fixed="bottom" className="bottom-navbar justify-content-around">
      <Nav className="justify-content-between w-100">
        <Nav.Link
          onClick={() => navigate('/home')}
          className={`d-flex flex-column align-items-center ${location.pathname === '/messages' ? 'home' : ''}`}
        >
          <FontAwesomeIcon icon={faHouse} size="lg" />
          <span>Home</span>
        </Nav.Link>
        <Nav.Link
          onClick={() => navigate('/finance')}
          className={`d-flex flex-column align-items-center ${location.pathname === '/finance' ? 'active' : ''}`}
        >
          <FontAwesomeIcon icon={faMoneyBillTransfer} size="lg" />
          <span>Financeiro</span>
        </Nav.Link>
        <Nav.Link className="d-flex flex-column align-items-center" onClick={handlePlusClick}>
          <div className="plus-icon">
            <FontAwesomeIcon icon={faPlus} size="lg" />
          </div>
        </Nav.Link>

        <Nav.Link
          onClick={() => navigate('/products')}
          className={`d-flex flex-column align-items-center ${location.pathname === '/products' ? 'active' : ''}`}
        >
          <FontAwesomeIcon icon={faTags} size="lg" />
          <span>Produtos</span>
        </Nav.Link>
        <Nav.Link
          onClick={() => navigate('/persona')}
          className={`d-flex flex-column align-items-center ${location.pathname === '/persona' ? 'active' : ''}`}
        >
          <FontAwesomeIcon icon={faUserFriends} size="lg" />
          <span>Persona</span>
        </Nav.Link>
      </Nav>
    </Navbar>
  );
};

export default BottomNavbar;